import { Input, TInputProps } from '@flounder/ui';
import { useFieldContext } from '../Form';

export function InputField(props: Omit<TInputProps, 'id' | 'errorMessage'>) {
  const {
    field,
    fieldState: { error },
  } = useFieldContext();

  const message = error?.message ? error.message : undefined;

  return <Input id={field.name} errorMessage={message} {...field} {...props} />;
}
