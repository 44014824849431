import { Button } from '../Button';
import { FieldError } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';
import classNames from 'classnames';

export interface FormProps {
  onSubmit(): void;
  children: JSX.Element | JSX.Element[];
  globalError?: FieldError;
  submitButton?: JSX.Element;
  className?: string;
}

export function Form({
  onSubmit,
  children,
  globalError,
  submitButton,
  className,
}: FormProps) {
  return (
    <form onSubmit={onSubmit} className={classNames(className)}>
      {children}
      {submitButton ? (
        submitButton
      ) : (
        <div className="w-fit ml-auto">
          <Button hierarchy="primary" type="submit" size="md" text="Submit" />
        </div>
      )}
      <div className="flex justify-center my-1">
        {globalError ? <ErrorMessage error={globalError} /> : null}
      </div>
    </form>
  );
}
