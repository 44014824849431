import { IExtendedUser } from '@flounder/contracts';
import { IUserApi } from '@flounder/shared-apis';
import { CognitoApi } from './CognitoApi/CognitoApi';

export enum CognitoErrorCode {
  NotAuthorizedException = 'NotAuthorizedException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  InvalidPasswordException = 'InvalidPasswordException',
  InvalidParameterException = 'InvalidParameterException',
  LimitExceededException = 'LimitExceededException',
}

export enum CognitoContext {
  ResetPassword = 'ResetPassword',
}

export enum CognitoAppErrorMessages {
  UserNotConfirmed = 'User account not confirmed. Check your mail',
  General = 'Something goes wrong',
  InvalidUserOrPassword = 'Invalid credentials',
  CodeMismatchException = 'Wrong verification code',
  ExpiredCodeException = 'Code expired. Resend new code',
}

export interface IUserNotConfirmedError extends CognitoError {
  email: string;
}

export class UserNotConfirmedError implements IUserNotConfirmedError {
  constructor(
    public code: CognitoErrorCode,
    public message: string,
    public email: string
  ) {}
}

export interface CognitoError {
  code: CognitoErrorCode;
  message: string;
}

export interface AuthProviderProps {
  cognitoApi: CognitoApi;
  ssrUser: IExtendedUser | null;
  authApi: IUserApi;
}
export interface IAuthUser extends IExtendedUser {
  fullName?: string;
}

export type IAuthContext = {
  cognitoApi?: CognitoApi;
  currentUser: IAuthUser | null;
  setCurrentAuthenticatedUser: () => Promise<void>;
};

export interface IAuthEnvs {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoUserPoolWebClientId: string;
  cognitoOAuthDomain: string;
  cognitoOAuthRedirectSignIn: string;
  cognitoOAuthRedirectSignOut: string;
}
