import { useCodeInput } from '@elpassion/taco';
import { InputCode, TInputCodeProps } from '@flounder/ui';
import { useCallback } from 'react';
import { useFieldContext } from '../Form';

export function InputCodeField({
  lengthOfCode = 6,
  ...props
}: Omit<TInputCodeProps, 'inputRefsArray' | 'onChange' | 'errorMessage'>) {
  const { inputRefsArray, generateCode } = useCodeInput(lengthOfCode);

  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useFieldContext();

  const message = error?.message ? error.message : undefined;

  const handleOnChange = useCallback(() => {
    onChange(generateCode().trim());
  }, [generateCode]);

  return (
    <InputCode
      inputRefsArray={inputRefsArray}
      onChange={handleOnChange}
      errorMessage={message}
      {...rest}
      {...props}
    />
  );
}
