import React from 'react';
import classNames from 'classnames';
import {
  MenuDropdown as TacoMenuDropdown,
  IMenuDropdownProps,
} from '@elpassion/taco/Dropdown';

type TDropdownProps = {
  className?: string;
} & Partial<Pick<IMenuDropdownProps, 'variant'>> &
  Omit<IMenuDropdownProps, 'variant'>;

export const MenuDropdown: React.FC<TDropdownProps> = ({
  className,
  variant = 'default',
  ...props
}) => {
  return (
    <TacoMenuDropdown
      variant={variant}
      className={classNames('top-full right-0', className)}
      {...props}
    />
  );
};
