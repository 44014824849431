export const PolishFlag = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4270_69900)">
      <rect width="22" height="16" rx="2" fill="#AF010D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H22V8H0V0Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4270_69900">
        <rect width="22" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
