export const routes = {
  home: '/',
  login: '/customauth/customsignin',
  signUp: '/customauth/customsignup',
  forgotPassword: '/customauth/forgotpassword',
  changePassword: '/users/change-password',
  users: '/users',
  userProfile: '/users/me',
  subscriptions: '/subscriptions',
  emails: '/emails',
  payment: '/payment',
  addressForm: '/addressForm',
  paymentSubscription: '/payment-subscription',
  support: '/support', // Page not existed yet
  subscriberArea: '/subscriber-area',
  subscriberContent: '/subscriber-content',
};
