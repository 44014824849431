import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IDropdownOption } from '@elpassion/taco/Dropdown';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid';
import { MenuDropdown } from '@flounder/ui';
import { PolishFlag } from './PolishFlag';
import { UnitedKingdomFlag } from './UnitedKingdomFlag';

const options = (lng?: string) => [
  {
    id: 'english',
    label: 'English',
    value: 'en',
    leftIcon: <UnitedKingdomFlag />,
    rightIcon: lng === 'en' && <CheckIcon className="text-primary-500" />,
  },
  {
    id: 'polish',
    label: 'Polski',
    value: 'pl',
    leftIcon: <PolishFlag />,
    rightIcon: lng === 'pl' && <CheckIcon className="text-primary-500" />,
  },
];

function getFromLocale(locale?: string) {
  return options().find(option => option.value === locale);
}

export const LangDropdown = () => {
  const router = useRouter();
  const { pathname, query, asPath, locale } = router;

  const [selectedLang, setSelectedLang] = useState<IDropdownOption | undefined>(
    getFromLocale(locale),
  );

  useEffect(() => {
    setSelectedLang(getFromLocale(locale));
  }, [locale]);

  const handleSelect = (option: IDropdownOption | IDropdownOption[] | undefined) => {
    if (!option || Array.isArray(option)) return;

    router.push({ pathname, query }, asPath, { locale: option.value });
  };

  return (
    <MenuDropdown
      options={options(selectedLang?.value)}
      selectedItems={selectedLang}
      onSelect={handleSelect}
      triggerButtonProps={{
        variant: 'outlined',
        leftIcon: selectedLang?.leftIcon,
        rightIcon: <ChevronDownIcon />,
        className: '!border-neutral-100 !text-neutral-500',
        text: '',
        id: 'language-change',
        ariaLabel: selectedLang?.id,
      }}
      className="w-[185px]"
    />
  );
};
